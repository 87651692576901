<div class="left side-menu">
  <div class="nav-menu m-t-20">
    <ul>
      <ng-template *ngFor="let item of menuData" [ngxPermissionsOnly]="item.permissions">
        <li>
          <a [routerLink]="item.routerLink" routerLinkActive="active">
            {{ item.name }}
          </a>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
export class TableParams {
  page = 0;
  size = 10;
  totalElements = 0;
  hasNextPage?: boolean;
  sort?: {
    prop: string,
    dir: string
  }[];
  sortDirection?: string;
}

export class DataTable {
  currentPage: number;
  hasNextPage: boolean;
  pageSize: number;
  results: any[];
  totalElements: number;
  message: string;
}

import { NgxUiLoaderConfig, NgxUiLoaderRouterConfig } from 'ngx-ui-loader';

const primaryColor = "#6A0502";
export const UILoaderConfig: NgxUiLoaderConfig = {
  bgsColor: primaryColor,
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: "double-bounce",
  blur: 10,
  delay: 0,
  fastFadeOut: true,
  fgsColor: primaryColor,
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "double-bounce",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(255,255,255,0.5)",
  pbColor: primaryColor,
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: true,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
};

export const UILoaderRouterConfig: NgxUiLoaderRouterConfig = {
  exclude: [
    '/#faqs',
    // 'situation-report',
    // 'conversation/*',
    // 'get-directions'
  ]
};

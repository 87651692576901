import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { OAuthToken } from '@shared/models/o-auth-token.model';

@UntilDestroy()
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private as: AuthService,
    private r: Router
  ) { }

  private applyCredentials = (request: HttpRequest<any>) => {
    const token = this.as.getAccessToken();
    const excludeUrl = /assets/gi;

    if (token && request.url.search(excludeUrl) === -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.get('exclude')) {
      return next.handle(request);
    } else {
      request = this.applyCredentials(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401 && this.as.getRefreshToken()) {

          // Refresh token first before invalidating session
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              untilDestroyed(this),
              switchMap((token) => {
                if (token) {
                  return next.handle(this.applyCredentials(request));
                }
                this.refreshTokenInProgress = false;
              }));
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            this.as.removeToken();

            return this.as.refreshToken(this.as.getRefreshToken())
              .pipe(
                untilDestroyed(this),
                switchMap((oauth: OAuthToken) => {
                  const newToken = oauth.access_token;
                  if (newToken) {
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this.applyCredentials(request));
                  }
                }),
                catchError((e) => {
                  this.goToLogin();
                  return throwError(e);
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                })
              );
          }
        }

        // return all others errors
        return throwError(error.error);

      })) as any;
  }

  private goToLogin(): void {
    this.as.clearToken();
    this.as.logout();
  }
}

<div class="d-flex flex-column full-height">
    <app-header>
        <div class="d-flex flex-row cg-20 align-self-center">
            <a class="link-button" nz-button nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" nzPlacement="bottomRight">{{ name | titlecase }}<span class="mdi mdi-menu-down"></span></a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item class="text-center" (click)="changePassword()">Change Password</li>
                </ul>
            </nz-dropdown-menu>
            <a class="link-button" (click)="logout()">Sign out</a>
        </div>
    </app-header>
    <div class="d-flex flex-row h-100">
        <app-sidebar></app-sidebar>
        <div class="d-flex flex-column content-page position-relative w-100">
            <div class="content">
                <div class="container-fluid app-container d-flex flex-column">
                    <div style="flex-grow: 1;">
                        <router-outlet></router-outlet>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@msu/core/services/auth.service';
import { environment as env } from '@env/environment';

@Component({
  selector: 'app-secured-base',
  templateUrl: './secured-base.component.html',
  styleUrls: ['./secured-base.component.scss']
})
export class SecuredBaseComponent implements OnInit {

  name: string;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.auth.getUser().subscribe(userData => {
      this.name = userData.name ? userData.name : userData.username;
    });
  }

  logout(): void {
    this.auth.logout();
  }

  changePassword(): void {
    this.performKeycloakAction('UPDATE_PASSWORD', 'change-password');
  }

  performKeycloakAction(action: string, route: string): void {
    location.href =
      `${env.authUrl}/auth/realms/cbsua/protocol/openid-connect/auth?client_id=web&redirect_uri=${env.appUrl}/` + route + `&response_type=code&kc_action=` + action;
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTableUtil } from '@shared/services/util/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private API_URL = '/api/v1/core';

  constructor(
    private httpClient: HttpClient,
    private du: DataTableUtil
  ) { }

  listMedia(tableParams): Observable<any> {
    const params = this.du.buildParams(tableParams);
    return this.httpClient.get<any>(this.API_URL + `/media/list`, { params });
  }

  downloadMedia(filename): Observable<any> {
    return this.httpClient.get<Blob>(this.API_URL + `/media/${filename}`, {
      responseType: 'blob' as 'json'
    });
  }

  uploadMedia(formData): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + `/media`, formData);
  }

  deleteMedia(filename): Observable<any> {
    return this.httpClient.delete<Blob>(this.API_URL + `/media/${filename}`);
  }

}

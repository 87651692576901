<div class="d-flex flex-column full-height">
    <app-header>
        <div class="d-flex flex-row cg-20 align-self-center align-items-center">
            <h4 class="mb-0 mt-0 font-normal"><a class="link-button" routerLink="/public/announcements"
                    routerLinkActive="active">ANNOUNCEMENTS</a></h4>
            <h4 class="mb-0 mt-0 font-normal"><a class="link-button" routerLink="/public/faq"
                    routerLinkActive="active">FAQs</a></h4>
            <button class="btn btn-primary w-sm" routerLink="/login">LOGIN</button>
        </div>
    </app-header>
    <div class="d-flex flex-row h-100">
        <div class="d-flex flex-column content-page position-relative w-100"
            [style.paddingTop]="atHome ? '0px' : '5rem' ">
            <div class="content">
                <div class="container-fluid app-container-public d-flex flex-column p-0 m-0">
                    <div style="flex-grow: 1;">
                        <router-outlet></router-outlet>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>
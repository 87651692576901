import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { RequestInterceptor } from '@core/interceptors/request.interceptor';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { environment } from '../../environments/environment';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NgSelectModule } from '@ng-select/ng-select';
import { SecuredBaseComponent } from '@msu/shared/components/layout/secured-base/secured-base.component';

const ngZorroConfig: NzConfig = {
  message: { nzMaxStack: 1, nzDuration: 5000, nzPauseOnHover: true },
  notification: { nzMaxStack: 3, nzDuration: 8000, nzPlacement: 'bottomRight' },
  modal: { nzMaskClosable: false, nzCloseOnNavigation: false },
  carousel: { nzEffect: 'scrollx', nzEnableSwipe: true, nzDots: true }
};

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showClearButton: true,
    clearPosition: 'center',
    clearButtonLabel: 'Clear Date',
    dateInputFormat: 'MMM-DD-YYYY',
    showWeekNumbers: false,
    containerClass: 'theme-red',
    adaptivePosition: true,
  });
}

export function getDaterangepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showClearButton: true,
    clearPosition: 'center',
    clearButtonLabel: 'Clear Date',
    rangeInputFormat: 'MMM-DD-YYYY',
    dateInputFormat: 'MMM-DD-YYYY',
    showWeekNumbers: false,
    containerClass: 'theme-dark-blue',
    adaptivePosition: true
  });
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    NzModalModule,
    NzNotificationModule,
    NzMessageModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzCarouselModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    {
      provide: BsDatepickerConfig,
      useFactory: getDatepickerConfig
    },
    {
      provide: BsDaterangepickerConfig,
      useFactory: getDaterangepickerConfig
    },
    BsLocaleService,
    AsyncPipe,
    SecuredBaseComponent
  ]
})
export class CoreModule { }

import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isPublic = true;
  isLoggedIn = false;
  atHome: boolean;

  constructor(
    private ar: ActivatedRoute,
    private r: Router
  ) {
    this.r.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url === '/public' || event.url === '/' ? this.atHome = true : this.atHome = false;
      }
    });
  }

  ngOnInit(): void {
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { SecuredBaseComponent } from './secured-base/secured-base.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SharedModule } from '@msu/shared/shared.module';


@NgModule({
  declarations: [
    BaseComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SecuredBaseComponent
  ],
  imports: [
    SharedModule,
    NzDropDownModule,
    CommonModule,
    RouterModule,
    FormsModule,
    RouterModule
  ]
})
export class LayoutModule { }

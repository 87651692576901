export class User {
    authenticated: boolean;
    authorities: { authority: string }[];
    credentials: any;
    details: {
        remoteAddress: string;
    };
    employeeNumber: string;
    name: string;
    principal: any;
    token: any;
    tokenAttributes: any;
    username: string;
    keycloakId?: any;
}

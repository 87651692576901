import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarousel, NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorUnauthorizedComponent } from './components/errors/error-unauthorized/error-unauthorized.component';
import { FroalaEditorDirective, FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UISwitchConfig } from './configs/ui-switch';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MediaLibraryPopupComponent } from './components/media-library-popup/media-library-popup.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    ErrorUnauthorizedComponent,
    MediaLibraryPopupComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NzPaginationModule,
    NzCollapseModule,
    NzDropDownModule,
    NzToolTipModule,
    UiSwitchModule.forRoot(UISwitchConfig),
    FontAwesomeModule,
    NzBadgeModule,
    NgSelectModule,
    NgxPermissionsModule
  ],
  exports: [
    NgbModule,
    NgbCarouselModule,
    FroalaViewModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FroalaEditorModule,
    BsDatepickerModule,
    NzPaginationModule,
    NzCollapseModule,
    NzDropDownModule,
    UiSwitchModule,
    FontAwesomeModule,
    NzBadgeModule,
    NgSelectModule,
    NgxPermissionsModule,
    NzToolTipModule
  ],
  providers: [FroalaEditorDirective]
})
export class SharedModule { }

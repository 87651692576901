import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    protected router: Router,
    protected auth: AuthService,
    protected modal: NzModalService,
    protected notification: NzNotificationService,
    protected l: NgxUiLoaderService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          // TODO: Handle error codes here
          this.l.stopAll();
          this.l.stop();
          switch (response.status) {
            case 400:
            case 404:
            case 500: {
              this.notification.create(
                'error',
                response.error && response.error.error || response.error && response.error.status,
                response.error && response.error.message || response.message
              );
              break;
              break;
            }
            case 502:
            case 503:
            case 504:
              this.notification.create(
                'error',
                'Error',
                'Unable to establish a connection to the Server.'
              );
          }
        }

        return throwError(response);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, CanActivateChild, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private permission: NgxPermissionsService,
    private ar: ActivatedRoute,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getAccessToken()) {
      const userRole = Object.keys(this.permission.getPermissions());
      if (userRole.length === 0) {
        return this.authService.getUser().pipe(map((user) => {
          const role = user.authorities.length > 0 ? user.authorities.map(auth => auth.authority) : null;
          this.permission.addPermission(role);
          return true;
        }),
          catchError(() => {
            this.router.navigateByUrl('/unauthorized');
            return of(false);
          }));
      }
      return true;
    }
    this.router.navigateByUrl('/public');
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableParams } from '@models/index';

@Injectable({
  providedIn: 'root'
})

export class DataTableUtil {

  constructor() {}

  public buildParams(tableParams: TableParams): HttpParams {

    let params = new HttpParams();

    params = params.set('page', encodeURIComponent(tableParams.page));
    params = params.set('size', encodeURIComponent(tableParams.size));
    if (tableParams.sort) {
      tableParams.sort.forEach((param) => {
        params = params.append('sort', param.prop + ',' + param.dir.toUpperCase());
      });
    }

    return params;
  }
}

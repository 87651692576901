import { Component, OnInit } from '@angular/core';
import { Permission } from '@msu/shared/enums/permission.enum';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menuData = [
    {
      routerLink: '/home',
      name: 'Home',
      permissions: [Permission.ROLE_GROUP_ADMIN, Permission.ROLE_GROUP_FACULTY, Permission.ROLE_GROUP_STUDENT, Permission.ROLE_GROUP_ACADEMICS, Permission.ROLE_GROUP_GUESTS, Permission.ROLE_GROUP_NONTEACHING_PERSONNEL]
    },
    {
      routerLink: '/announcements',
      name: 'Announcements',
      permissions: [Permission.ROLE_GROUP_ADMIN, Permission.ROLE_GROUP_FACULTY, Permission.ROLE_GROUP_STUDENT, Permission.ROLE_GROUP_ACADEMICS, Permission.ROLE_GROUP_GUESTS, Permission.ROLE_GROUP_NONTEACHING_PERSONNEL]
    },
    {
      routerLink: '/faq',
      name: 'FAQs',
      permissions: [Permission.ROLE_GROUP_ADMIN, Permission.ROLE_GROUP_FACULTY, Permission.ROLE_GROUP_STUDENT, Permission.ROLE_GROUP_ACADEMICS, Permission.ROLE_GROUP_GUESTS, Permission.ROLE_GROUP_NONTEACHING_PERSONNEL]
    },
    {
      routerLink: '/route',
      name: 'Route Config',
      permissions: [Permission.ROLE_GROUP_ADMIN]
    },
    {
      routerLink: '/media',
      name: 'Media Library',
      permissions: [Permission.ROLE_GROUP_ADMIN]
    },
  ];

  constructor(
    private ps: NgxPermissionsService
  ) { }

  ngOnInit(): void {
    console.log(this.ps.getPermissions());
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { OAuthToken } from '@shared/models/o-auth-token.model';
import { User } from '@shared/models/user.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, tap, mergeMap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserInfo$: Observable<any>;
  public isLoggedIn$ = new BehaviorSubject<boolean>(this.hasToken());

  constructor(
    private httpClient: HttpClient,
    private p: NgxPermissionsService,
    private r: Router
  ) { }

  public login(code: string): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('grant_type', 'authorization_code');
    params = params.append('client_id', 'web');
    params = params.append('code', code);
    params = params.append('redirect_uri', `${env.appUrl}/login`);

    return this.httpClient.post<any>(`${env.authUrl}/auth/realms/cbsua/protocol/openid-connect/token`, params,
    { headers })
      .pipe(
        mergeMap((oauth: OAuthToken) => {
          this.saveAccessToken(oauth.access_token);
          this.saveRefreshToken(oauth.refresh_token);
          this.isLoggedIn$.next(true);
          return of(true);
        })
      );
  }

  public refreshToken(refreshToken: string): Observable<OAuthToken> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('grant_type', 'refresh_token');
    params = params.append('client_id', 'web');
    params = params.append('refresh_token', refreshToken);

    return this.httpClient.post<any>(`${env.authUrl}/auth/realms/cbsua/protocol/openid-connect/token`, params,
      { headers })
      .pipe(
        tap((oauth: OAuthToken) => {
          this.saveAccessToken(oauth.access_token);
          this.saveRefreshToken(oauth.refresh_token);
          return oauth;
        })
      );
  }

  public getUser(): Observable<User> {
    if (!this.currentUserInfo$) {
      this.currentUserInfo$ = this.httpClient.get<User>('/api/v1/core/me')
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.currentUserInfo$;
  }

  public saveAccessToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public saveRefreshToken(token: string): void {
    localStorage.setItem('refresh_token', token);
  }

  public removeToken(): void {
    localStorage.removeItem('access_token');
  }

  public getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  public clearToken(): void {
    localStorage.clear();
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('access_token');
  }

  logout(): void {
    this.p.flushPermissions();
    this.clearToken();
    location.href = `${env.authUrl}/auth/realms/cbsua/protocol/openid-connect/logout?redirect_uri=${env.appUrl}/`;
  }
}


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TableParams } from '@msu/shared/models';
import { MediaService } from '@msu/shared/services/api/media-library/media.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-media-library-popup',
  templateUrl: './media-library-popup.component.html',
  styleUrls: ['./media-library-popup.component.scss']
})
export class MediaLibraryPopupComponent implements OnInit {
  tableParams = new TableParams();
  form: FormGroup;
  mediaListArray = [];
  files: any;
  imagesArray = [];
  selectedFile: null;

  constructor(
    private mls: MediaService,
    private fb: FormBuilder,
    private nzms: NzNotificationService,
    private s: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getMedia();
  }

  upload(): void {
    const form = this.form.getRawValue();
    const formData = new FormData();
    formData.append('file', form.file);
    this.mls.uploadMedia(formData).subscribe(result => {
      this.showMessage('success', 'Image Uploaded');
      this.getMedia();
    });
  }

  triggerFileChange(event): void {
    this.form.get('file').setValue(event.target.files[0]);
    event.target.files = null;
  }

  getImageUrl(filename): any {
    const file = this.imagesArray.filter(e => e.fileName === filename);
    return file[0]?.url;
  }


  getMedia(): void {
    this.imagesArray = [];
    this.mls.listMedia(this.tableParams).subscribe(data => {
      this.mediaListArray = data;
      data.forEach(image => {
        this.mls.downloadMedia(image.filename).subscribe(blobData => {
          const objectURL = URL.createObjectURL(blobData);
          const url = this.s.bypassSecurityTrustUrl(objectURL);
          const data = {
            fileName: image.filename,
            lastModifiedDate: image.lastModifiedDate,
            url
          };
          this.imagesArray.push(data);
        });
      });
    });
  }

  getUrl(url): any {
    return `url("${url.changingThisBreaksApplicationSecurity}")`;
  }

  buildForm(): void {
    this.form = this.fb.group({
      file: [null]
    });

    this.form.get('file').valueChanges.subscribe(data => {
      this.upload();
    });
  }

  showMessage(type, message): void {
    if (type === 'error') {
      this.nzms.create('error', 'Error', message);
    } else if (type === 'success') {
      this.nzms.create('success', 'Success', message);
    }
  }

  selectFile(filename): void {
    this.selectedFile = filename;
  }


}

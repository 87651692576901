import { UiSwitchModuleConfig } from "ngx-ui-switch/ui-switch/ui-switch.config";

const primaryColor = "#6A0502";
const primaryColor2 = "rgba(106, 5, 2, 0.5)";
const white = "rgba(255,255,255,1)";

export const UISwitchConfig: UiSwitchModuleConfig = {
  size: 'medium',
  color: primaryColor2,
  switchColor: primaryColor,
  defaultBgColor: white,
  defaultBoColor : primaryColor,
};


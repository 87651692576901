import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@msu/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor(
    private as: AuthService,
    private r: Router
  ) { }

  ngOnInit(): void {
  }

  goHome(): void {
    if (this.as.getAccessToken()) {
      this.r.navigate(['/home']);
    } else {
      this.r.navigate(['/public']);
    }
  }

}
